
import { mapState, mapMutations, mapActions } from 'vuex'

import * as animationData from '~/assets/lottie/loader.json'
import mainMenu from '~/assets/menus/main.json'
import mobileMenu from '~/assets/menus/mobile-menu.json'
import footerMenu from '~/assets/menus/footer.json'
import logos from '~/assets/icons/icons.json'

export default {
  name: 'DefaultLayout',
  data() {
    return {
      logos,
      mainMenu,
      mobileMenu,
      footerMenu,
      locationIcon: null,
      chevron: {
        name: 'chevron-right-icon',
        classes: 'chevron black',
      },
      rewardsTrophy: {
        name: 'award-icon',
        classes: 'award-icon',
      },
      lottieData: animationData.default,
      cartConfig: {
        headerInfo: {
          title: 'Your Cart',
          leftAction: {
            show: true,
            text: '',
            iconName: 'arrow-left-icon',
            iconClasses: '',
            callback: () => {
              this.$nuxt.$router.push('/menu')
              this.hideCart()
            }
          },
          rightAction: {
            show: true,
            text: this.isMobile ? 'Change' :'Change Location',
            iconName: 'location-icon',
            iconClasses: '',
            callback: () => {
              this.$nuxt.$router.push('/locations')
              this.hideCart()
            }
          },
        },
        cartTitle: {
          title: 'Your Order',
          leftAction: {
            show: true,
            iconName: 'order-icon',
            iconClasses: '',
          },
          rightAction: {
            show: true,
            text: this.isMobile ? 'Clear Items' : 'Clear All Items',
            iconName: 'trash-icon',
            iconClasses: '',
            callback: async () => {
              await this.abandonCart()
              this.hideCart()
            }
          },
        },
        cartUpsells: {
          title: 'Complete Your Order',
          leftAction: {
            show: true,
            text: '',
            iconName: 'arrow-left-icon',
            iconClasses: '',
            callback: () => {
              this.$nuxt.$router.push('/menu')
              this.hideCart()
            }
          },
          rightAction: {
            show: false,
          },
          navigationArrows: {
            leftIconName: 'arrow-left-icon',
            leftIconClass: '',
            rightIconName: 'arrow-right-icon',
            rightIconClass: '',
          },
        },
        handoffInfo: {
          title: 'Order Handoff',
        },
        timeInfo: {
          title: 'Order Time',
        },
        locationCardInfo: {
          separator: '•',
          showServicesOnCard: false,
          showServicesOnOperation: false,
          locationPageAction: '',
          locationPageIcon: '',
        },
      },
      siteOverlayConfig: {
        exactListBlocked: [
          '/account/signin',
          '/account/signup',
          '/account/forgot',
          '/account/confirmed',
          '/account/signin/',
          '/account/signup/',
          '/account/forgot/',
          '/account/confirmed/',
        ],
        fragmentListBlocked: ['location', 'menu', 'order'],
        actions: [
          {
            name: 'Order Now',
            class: 'btn',
            iconName: '',
            iconClasses: '',
            callback: () => {
              if (this.cart) {
                if (this.cart.products && this.cart.products.lenght > 0) {
                  return this.$nuxt.$router.push('/checkout/order')
                }
                return this.$nuxt.$router.push(`/menu/${this.cart.menu_id}`)
              }
              if (this.location) {
                return this.$nuxt.$router.push(`/menu/${this.location.menu_id}`)
              }
              return this.$nuxt.$router.push('/locations')
            },
          },
        ],
      },
      mobileMenuConfig: {
        header: {
          buttonText: '',
          buttonClass: '',
          iconName: 'cross-icon',
          iconClasses: '', 
        },
        greetings: {
          title: {
            iconName: 'rewards-icon',
            iconClasses: 'title-icon',
            text: 'Rewards',
          },
          welcomeText: 'Welcome back,',
          offers: {
            iconName: '',
            iconClasses: '',
            text: 'Rewards',
            callback: () => {
              this.setActiveAccountSection('rewards-single')
              return this.$router.push('/account?section=rewards-single')
            },
          },
          points: {
            iconName: '',
            iconClasses: '',
            preText: 'Balance',
            pointsText: 'Pts',
            callback: () => {
              this.setActiveAccountSection('user-info')
              return this.$router.push('/account?section=user-info')
            },
          },
          buttons: [
            {
              iconName: '',
              iconClasses: '',
              text: 'Order Now',
              callback: () => {
                return this.$router.push('/menu')
              },
            },
          ],
        },
        signin: {
          text: 'Sign in'
        },
      },
      mainNavConfig: {
        customMenu: {
          text: '',
          iconName: 'burger-menu-icon',
          iconClasses: '',
          callback: () => this.toggleNav()
        },
        cartIcon: {
          class: 'cart-icon-icon',
          name: 'shopping-cart-icon',
        },
        greetings: {
          showUserName: false,
          signInText: 'Sign In',
          points: {
            pointsText: 'Points',
          },
        },
      },
      tokenConfig: {
        forceRefreshToken: true,
        provider: 'spendgo',
        message: `For your security you've been signed out. Please sign in again to continue. This may be required when you're accessing your account from multiple devices or there is something different about your most recent sign in attempt. Thanks for your understanding!`,
        actions: [
          {
            type: 'internal',
            to: '/account/signin',
            title: 'Sign In',
            class: 'primary',
          },
          {
            type: 'button',
            callback: () => this.$router.push('/'),
            title: 'Dismiss',
            class: 'secondary',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState([ 'location', 'cart', 'setShowNav' ]),
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
  },
  methods: {
    ...mapMutations([ 'setShowCart', 'setActiveAccountSection' ]),
    ...mapActions([ 'abandonCart' ]),
    hideCart() {
      document.body.style.overflow = 'auto'
      this.setShowCart(false)
    },
    toggleNav() {
      document.body.style.overflow = !this.showNav ? 'hidden' : 'auto'
      this.$store.commit('setShowNav', !this.showNav)
    }
  }
}
